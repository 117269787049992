<template>
  <div id="app">
    <WordBank />
  </div>
</template>

<script>
import WordBank from './components/WordBank.vue';

export default {
  name: 'App',
  components: {
    WordBank
  }
};
</script>
